.portfolio-card {
  padding: 20px 10px;
  margin: 0 20px;
  border: 1px solid #6093c2;
  border-radius: 10px;
  box-shadow: 10px 10px 10px #6093c2;
}

.portfolio-card:hover {
  box-shadow: 10px 10px 10px #1cfdff;
  color: #fff;
  border: 1px solid #1cfdff;
}

.portfolio-button-github {
  padding: 10px 20px;
  border: 1px solid #6093c2;
  color: #6093c2;
}

.portfolio-button-github:hover {
  background-image: linear-gradient(to top right, #c339f8, #6093c2);
  box-shadow: 10px 10px 10px #6093c2;
  color: #fff;
  border: none;
}

.portfolio-image {
  width: 100%; /* This will make the image take up the full width of its container */
  height: 200px; /* Adjust as per your needs */
  object-fit: cover; /* This will ensure your images cover the full width and height without distortion */
}
