/* .Skills {
  color: #ffd700;
  font-size: 48px;
  margin: 0;
  margin-left: 150px;
  margin-top: 15px;
  font-family: "Coolvetica";
  font-weight: 400;
} */
.Cloud-container {
  width: 100%; /* Adjust the size of the circle container */
  height: 100%; /* Adjust the size of the circle container */

  border-radius: 50%; /* Make the container circular */
  transform: translateX(45%) translateY(-3%); /* Center the container horizontally */
  /* Add a simple scale animation */
  animation: scale-animation 3s infinite alternate ease-in-out;
}

@media (max-width: 767px) {
  .Cloud-container {
    transform: translateX(-15%) translateY(0%); /* Center the container horizontally */
  }
}
