h1 {

    color: #fff;
    font-size: 45px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
        // content: '<h1>';
        font-family: 'La Belle Aurore';
        color: #ffd700;
        position: absolute;
        margin-top: -40px;
        left: 15px;
        opacity: 0.6;
    }

    &:after {
        // content: '<h1>';
        font-family: 'La Belle Aurore';
        color: #ffd700;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;

    }


}

h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 20px;
    font-size: 12px;
    font-family: 'sans-serif';
    letter-spacing: 3px;
    // animation: fadeIn 1s 1.8s backwards;
}

.flat-button {
    color: #ffd700;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: 'sans-serif';
    //this removes the underline from Contact Me
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #6093c2;
    color: #6093c2;
    margin-top: 20px;
    float: left;
    // animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;


    &:hover {
        background-image: linear-gradient(to top right, #c339f8, #6093c2);
        box-shadow: 10px 10px 10px #6093c2;
        color: #fff;
        border: none;
    }
}

@media screen and (max-width: 1200px) {
    .text-zone {
    position: initial;
    width: 100%;
    transform: none;
    padding: 10px;
    box-sizing: border-box;
    }
}

