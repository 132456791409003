.navlink:hover {
  color: #6093c2;
  border: none;
  outline: none;
}



.home-link:hover{

  border: none;
  svg {
    opacity: 0;
  }
  &:after {
    content: 'Home';
  }

}